document.addEventListener('DOMContentLoaded',function(){
  const screenSize = window.innerWidth
  setTimeout(function (){
    let newsCards = document.querySelectorAll('.card-news') || [];

    for (let newsCard of newsCards) {
      const title = newsCard.querySelector('.card-news__title') || null
      const description = newsCard.querySelector('.card-news__description') || null
      const content = newsCard.querySelector('.card-news__content') || null
      // const titleHeight = window.getComputedStyle(title).getPropertyValue('height')
      const titleHeight = title.offsetHeight
      if (screenSize >= 992) {
        if (titleHeight > 50 && titleHeight < 100 ) {
          description.style.webkitLineClamp = '3'
          description.style.maxHeight = '4.875em'
        } else if (titleHeight > 100 ) {
          description.style.webkitLineClamp = '1'
          description.style.maxHeight = '1.625em'
        }
      } else {
        if (titleHeight > 50 && titleHeight < 75) {
          description.style.webkitLineClamp = '4'
          description.style.maxHeight = '5.334em'
          description.style.overflow = 'hidden'
          description.style.display = '-webkit-box'
        } else if (titleHeight > 75 && titleHeight < 100) {
          description.style.webkitLineClamp = '3'
          description.style.maxHeight = '4.002em'
          description.style.overflow = 'hidden'
          description.style.display = '-webkit-box'
        } else if (titleHeight > 100) {
          description.style.webkitLineClamp = '1'
          description.style.maxHeight = '1.334em'
          description.style.overflow = 'hidden'
          description.style.display = '-webkit-box'
        }
      }
        // if (titleHeight > 40 && titleHeight < 60){
        //   description.style.webkitLineClamp = '3'
        //   description.style.maxHeight = '4.875em'
        // } if else (titleHeight > 60 && titleHeight < 80){
        //   description.style.webkitLineClamp = '2'
        //   description.style.maxHeight = '1.625em'
        // } if else (titleHeight > 80){
        //   description.style.webkitLineClamp = '1'
        //   description.style.maxHeight = '1.625em'
        // }
    }
  }, 500)

})
